export default [
  {
    path: '/settings/email-settings',
    name: 'email-settings',
    component: () => import('./views/EmailSettingsView.vue')
  },
  {
    path: '/settings/email-settings/smtps',
    name: 'email-settings.smtps',
    component: () => import('./views/SMTPsListView.vue')
  },
  {
    path: '/settings/email-settings/smtps/:smtpId',
    name: 'email-settings.smtp.detail',
    component: () => import('./views/SMTPDetailView.vue')
  },
  {
    path: '/settings/email-settings/templates',
    name: 'email-settings.templates',
    component: () => import('./views/TemplatesListView.vue')
  },
  {
    path: '/settings/email-settings/templates/:templateName',
    name: 'email-settings.template.detail',
    component: () => import('./views/TemplateDetailView.vue')
  },

]
