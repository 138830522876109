import request from './../services/request.service';
import Interface from './../services/api.interface';

export default {
  languages: new Interface({basePath: "intl/languages", model: "language", primaryKey: "locale"}),
  countries: new Interface({basePath: "intl/countries", model: "country", primaryKey: "countryCode"}),
  collections: new Interface({
    basePath: "intl/collections",
    model: "collection",
    primaryKey: "name",
    extend: {
      entity: function(collection){
        return {
          parameters: new Interface({
            basePath: `intl/collections/${collection.name}/parameters`,
            model: "parameter",
            primaryKey: "name",
            extend: {
              entity: function(parameter){
                return {
                  updateTranslations(translations){
                    return request('PUT',`intl/collections/${collection.name}/parameters/${parameter.name}/translations`,{
                      data: {translations}
                    });
                  },
                }
              }
            }
          })
        }
      }
    }
  })
}
