export default {
    namespaced: true,
    state: {
      active: {
        module: null,
        page: null,
        realm: null,
        subpage: {
          name: null,
          entity: null,
          child: null
        }
      }
    },
    mutations: {
      updateActive(state,data){
        state.active = Object.assign({
          subpage: {
            name: null,
            entity: null,
            child: null
          }
        },data);
      },
      updateActiveSubpage(state,data){
        state.active.subpage = Object.assign({},data);
      }
    },
    getters: {
      getActive(state){
        return state.active;
      },
    },
    actions: {
      setActive({commit}, data){
        return commit('updateActive', data);
      },
      setActiveSubpage({commit},data){
        return commit('updateActiveSubpage', data);
      }
    }
};
