import request from './../services/request.service';
import Interface from './../services/api.interface';

export default {
  templates: new Interface({
    basePath: "emailer/templates", model: "template", primaryKey: "name",
    extend: {
      entity: function(template){
        return {
          updateTranslations(translations){
            return request('PUT',`emailer/templates/${template.name}/translations`,{
              data: {translations}
            });
          }
        }
      }
    }
  }),
  smtps: new Interface({
    basePath: "emailer/smtps",
    model: "smtp"
  })
}
