export default [
  {
    path: '/settings/intl',
    name: 'intl',
    component: () => import('./views/IntlView.vue')
  },
  {
    path: '/settings/intl/languages',
    name: 'intl.languages',
    component: () => import('./views/LanguagesListView.vue')
  },
  {
    path: '/settings/intl/collections',
    name: 'intl.collections',
    component: () => import('./views/CollectionsListView.vue')
  },
  {
    path: '/settings/intl/collection/:collectionName',
    name: 'intl.collection.details',
    component: () => import('./views/CollectionDetailView.vue')
  },
  {
    path: '/settings/intl/collection/:collectionName/:parameterName',
    name: 'intl.collection.parameter.details',
    component: () => import('./views/ParameterDetailView.vue')
  },
]
