import { createStore } from 'vuex'
import Navigation from './Navigation';
import Auth from './Auth';

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Auth: Auth,
    Navigation: Navigation
  }
})
