import { createRouter, createWebHistory } from 'vue-router';
import auth from './middlewares/auth.middleware';
//import guard from './middlewares/guard.middleware';
import realmRoutes from '@/modules/realms/routes';
import emailSettingsRoutes from '@/modules/email-settings/routes';
import internationalizationRoutes from '@/modules/internationalization/routes';
import reportsRoutes from '@/modules/reports/routes';
import masterUsersRoutes from '@/modules/master-users/routes';

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('@/views/DashboardView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue')
  }
]
.concat(realmRoutes)
.concat(emailSettingsRoutes)
.concat(internationalizationRoutes)
.concat(reportsRoutes)
.concat(masterUsersRoutes)

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => auth({to, from, next, router}));
//router.beforeEach((to, from, next) => guard({to, from, next, router}));

export default router
