export default [
  {
    path: 'settings',
    name: 'realm.settings',
    component: () => import('./realm/views/SettingsRouterView'),
    children: [
      {
        path: "",
        name: 'realm.settings.details',
        component: () => import('./realm/views/EditDetailsView'),
      },
      {
        path: "emails-templates",
        name: 'realm.settings.email-templates',
        component: () => import('./realm/views/emails/EmailTemplatesListView'),
      },
      {
        path: "emails-templates/:templateName",
        name: 'realm.settings.email-templates.details',
        component: () => import('./realm/views/emails/EmailTemplateDetailView'),
      },
      {
        path: "emails-smtps",
        name: 'realm.settings.email-smtps',
        component: () => import('./realm/views/emails/EmailSMTPsListView'),
      },
      {
        path: "emails-smtps/:smtpId",
        name: 'realm.settings.email-smtps.details',
        component: () => import('./realm/views/emails/EmailSMTPDetailView'),
      },
      {
        path: "users",
        name: 'realm.settings.users',
        component: () => import('./realm/views/UsersSettingsView'),
      },
      {
        path: "branding",
        name: 'realm.settings.branding',
        component: () => import('./realm/views/BrandingView'),
      },
      {
        path: "hosts",
        name: 'realm.settings.hosts',
        component: () => import('./realm/views/HostsView'),
      },
      {
        path: "certs",
        name: 'realm.settings.certs',
        component: () => import('./realm/views/CertificatesView'),
      },
      {
        path: "admins",
        name: 'realm.settings.admins',
        component: () => import('./realm/views/AdminsView'),
      },
      {
        path: "remove",
        name: 'realm.settings.remove',
        component: () => import('./realm/views/RealmDeleteView'),
      },
    ]
  },
]
