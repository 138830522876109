import usersRoutes from './modules/users/routes';
import realmSettings from './modules/settings/routes';
import orgRoutes from './modules/organizations/routes';

export default [
  {
    path: '/realms',
    name: 'realms',
    component: () => import('./views/RealmsListView')
  },
  {
    path: '/realms/:realmId',
    component: () => import('./views/RealmRouterView'),
    children: [
      {
        path: "",
        name: 'realm.dashboard',
        component: () => import('./views/RealmDashboardView'),
      }
    ]
    .concat(realmSettings)
    .concat(usersRoutes)
    .concat(orgRoutes)
  },
]
