export default [
  {
    path: '/settings/users',
    name: 'users',
    component: () => import('./views/UsersSettingsView.vue')
  },


  {
    path: '/settings/users/roles',
    name: 'users.roles',
    component: () => import('./views/roles/RolesListView.vue')
  },
  {
    path: '/settings/users/roles/:roleId',
    name: 'users.roles.role.detail',
    component: () => import('./views/roles/RoleDetailView.vue')
  },
  {
    path: '/settings/users/roles/:roleId/policies',
    name: 'users.roles.role.detail.policies',
    component: () => import('./views/roles/RoleDetailView.vue')
  },


  {
    path: '/settings/users/plans',
    name: 'users.plans',
    component: () => import('./views/plans/PlansListView.vue')
  },
  {
    path: '/settings/users/plans/:planId',
    name: 'users.plans.plan.detail',
    component: () => import('./views/plans/PlanDetailView.vue')
  },
  {
    path: '/settings/users/plans/:planId/policies',
    name: 'users.plans.plan.detail.roles',
    component: () => import('./views/plans/PlanDetailView.vue')
  },


  {
    path: '/settings/users/permissions',
    name: 'users.permissions',
    component: () => import('./views/permissions/PermissionsListView.vue')
  },
  {
    path: '/settings/users/policies',
    name: 'users.policies',
    component: () => import('./views/policies/PoliciesListView.vue')
  },
  {
    path: '/settings/users/policies/:policyId',
    name: 'users.policies.policy.detail',
    component: () => import('./views/policies/PolicyDetailView.vue')
  },
  {
    path: '/settings/users/policies/:policyId/permissions',
    name: 'users.policies.policy.detail.permissions',
    component: () => import('./views/policies/PolicyDetailView.vue')
  },
]
