import {createI18n} from 'vue-i18n/index';
import messages from '@/lang';

const i18n = createI18n({
  locale: process.env.VUE_APP_LANGUAGE,
  fallbackLocale: process.env.VUE_APP_LANGUAGE_FALLBACK,
  messages
});

export default i18n;
