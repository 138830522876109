import { createApp } from 'vue';
import App from './App.vue';

const app =  createApp(App);

import store from './services/store';
app.use(store);

import router from './services/router';
app.use(router);

import i18n from './services/i18n.service';
app.use(i18n);

import bootstrap from './services/bootstrap.service';
app.use(bootstrap);

import api from './services/rest-api';
app.use(api);

import "./assets/style.css";

app.mount('#app')
