import request,{requestUrl} from './../services/request.service';
import Interface from './../services/api.interface';

const defaults = {
  permissions: new Interface({basePath: "iam/defaults/permissions", model: "permission"}),
  policies: new Interface({
    basePath: "iam/defaults/policies", model: "policy",
    extend: {
      entity: function(policy){
        return {
          addPermission(permissionId){
            return request('PUT',`iam/defaults/policies/${policy.id}/permissions`,{
              data: {
                permissions: [permissionId]
              }
            });
          },
          removePermission(permissionId){
            return request('DELETE',`iam/defaults/policies/${policy.id}/permissions`,{
              data: {
                permissions: [permissionId]
              }
            });
          },
        }
      }
    }
  }),
  roles: new Interface({
    basePath: "iam/defaults/roles", model: "role",
    extend: {
      entity: function(role){
        return {
          addPolicy(policyId){
            return request('PUT',`iam/defaults/roles/${role.id}/policies`,{
              data: {
                policies: [policyId]
              }
            });
          },
          removePolicy(policyId){
            return request('DELETE',`iam/defaults/roles/${role.id}/policies`,{
              data: {
                policies: [policyId]
              }
            });
          },
        }
      }
    }
  }),
  plans: new Interface({
    basePath: "iam/defaults/plans", model: "plan",
    extend: {
      entity: function(plan){
        return {
          addRole(roleId){
            return request('PUT',`iam/defaults/plans/${plan.id}/roles`,{
              data: {
                roles: [roleId]
              }
            });
          },
          removeRole(roleId){
            return request('DELETE',`iam/defaults/plans/${plan.id}/roles`,{
              data: {
                roles: [roleId]
              }
            });
          },
          syncFeatures(features){
            return request('POST',`iam/defaults/plans/${plan.id}/features`,{
              data: {features}
            });
          }
        }
      }
    }
  },{
    features: new Interface({basePath: "iam/defaults/plans-features"})
  }),
};

const realms = new Interface({
  basePath: "iam/realms",
  model: "realm",
  extend: {
    entity: function(realm){
      return {
        users: new Interface({
          basePath: `iam/realms/${realm.id}/users`,
          model: "user",
          extend: {
            entity: function(usr,model){
              return {
                update: {
                  profile(data){
                    return request('PUT',`iam/realms/${realm.id}/users/${usr.id}/profile`,{data})
                      .then(result => {
                        result.data = Object.assign({}, result.data, model(result.data));
                        return result;
                      });
                  },
                  email(data){
                    return request('PUT',`iam/realms/${realm.id}/users/${usr.id}/email`,{data})
                      .then(result => {
                        result.data = Object.assign({}, result.data, model(result.data));
                        return result;
                      });
                  },
                  password(data){
                    return request('PUT',`iam/realms/${realm.id}/users/${usr.id}/password`,{data});
                  },
                  destroy(data){
                    return request('DELETE',`iam/realms/${realm.id}/users/${usr.id}`,{data});
                  },
                },
                profileImage: {
                  getImagePath(){
                    return requestUrl(`iam/public/realms/${realm.id}/users/${usr.id}/${usr.profileImageFilename}`);
                  },
                  getUploadUrl(){
                    return requestUrl(`iam/realms/${realm.id}/users/${usr.id}/profile-image`);
                  },
                  destroy(){
                    return request('DELETE',`iam/realms/${realm.id}/users/${usr.id}/profile-image`);
                  }
                },
                subaccounts: new Interface({
                  basePath: `iam/realms/${realm.id}/users/${usr.id}/subaccounts`,
                  model: "user",
                }),
                organizations: new Interface({
                  basePath: `iam/realms/${realm.id}/users/${usr.id}/organizations`,
                  model: "organization",
                })
              }
            }
          }
        }),
        hosts: new Interface({
          basePath: `iam/realms/${realm.id}/hosts`,
          model: "host",
        }),
        videoHosts: new Interface({
          basePath: `iam/realms/${realm.id}/video-hosts`,
          model: "vhost",
        }),
        certificate: {
          details(){
            return request('GET',`iam/realms/${realm.id}/certificate`);
          },
          regenerate(){
            return request('PUT',`iam/realms/${realm.id}/certificate/regenerate`);
          },
        },
        branding: new Interface({
          basePath: `iam/realms/${realm.id}/branding`,
          model: "branding",
          extend: {
            entity: function(item){
              if(item.datatype === 'image'){
                return {
                  getImagePath(){
                    return requestUrl(`iam/public/realms/${realm.id}/branding/${item.value}`);
                  },
                  getUploadUrl(){
                    return requestUrl(`iam/realms/${realm.id}/branding/${item.id}`);
                  },
                }
              }
              return {};
            }
          }
        }),
        smtps: new Interface({
          basePath: `emailer/realm/${realm.id}/smtps`,
          model: "smtp",
        }),
        emailTemplates: new Interface({
          basePath: `emailer/realm/${realm.id}/templates`,
          model: "template",
          extend: {
            entity: function(tpl){
              return {
                updateTranslations(translations){
                  return request('PUT',`emailer/realm/${realm.id}/templates/${tpl.name}/translations`,{
                    data: {translations}
                  });
                }
              }
            }
          }
        }),
        organizations: new Interface({
          basePath: `iam/realms/${realm.id}/organizations`,
          model: "organization",
        }),
      }
    }
  }
})

const user = function(usr){
  return {
    logout(){
      return request('DELETE','iam/user/sessions')
    },
    profileImage: {
      getImagePath(){
        return requestUrl(`iam/public/realms/${usr.realmId}/users/${usr.id}/${usr.profileImageFilename}`);
      }
    }
  }
};

const auth = {
  login(usernameOrEmail,password){
    return request('POST','auth/user',{
      data: {usernameOrEmail,password}
    });
  },
  refreshTokens({accessToken,refreshToken}){
    return request('GET',`auth/refresh-tokens`,{
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Authorization-Refresh-Token': refreshToken,
      }
    });
  }
}

export default {
  defaults, realms, user, auth
}
