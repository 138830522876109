export default [
  {
    path: 'users',
    name: 'realm.users',
    component: () => import('./views/UsersListView')
  },
  {
    path: 'users/new',
    name: 'realm.users.new',
    component: () => import('./views/NewUserView')
  },
  {
    path: 'users/:userId',
    component: () => import('./views/user/UserRouterView'),
    children: [
      {
        path: "",
        name: 'realm.users.user.details',
        component: () => import('./views/user/UserDetailView'),
      },
      {
        path: "edit",
        name: 'realm.users.user.editAccount',
        component: () => import('./views/user/UserEditAccountView'),
      },
      {
        path: "plan-details",
        name: 'realm.users.user.plan',
        component: () => import('./views/user/UserPlanDetailView'),
      },
      {
        path: "subaccounts",
        name: 'realm.users.user.subaccounts',
        component: () => import('./views/user/UserSubaccountsView'),
      },
      {
        path: "change-password",
        name: 'realm.users.user.changePassword',
        component: () => import('./views/user/UserChangePasswordView'),
      },
      {
        path: "change-email",
        name: 'realm.users.user.changeEmail',
        component: () => import('./views/user/UserChangeEmailView'),
      },
      {
        path: "access-management",
        name: 'realm.users.user.access',
        component: () => import('./views/user/UserAccessManagementView'),
      },
      {
        path: "widgets",
        name: 'realm.users.user.widgets',
        component: () => import('./views/user/UserWidgetsListView'),
      },
      {
        path: "organizations",
        name: 'realm.users.user.organizations',
        component: () => import('./views/user/UserOrganizationsView'),
      },
      {
        path: "call-history",
        name: 'realm.users.user.callHistory',
        component: () => import('./views/user/UserCallHistoryView'),
      },
      {
        path: "remove-account",
        name: 'realm.users.user.removeAccount',
        component: () => import('./views/user/UserRemoveAccountView'),
      },
    ]
  },
]
