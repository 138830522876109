import iam from './collections/iam.collection';
import intl from './collections/intl.collection';
import emailer from './collections/emailer.collection';

export {iam,intl,emailer};

export default {
  install: (app) => {
    app.config.globalProperties.$api = {
      iam,intl,emailer
    };
  }
}
